
var Modals = {};

(function (Modals) {

    var ids = {
        loadingModal: "#loading-modal",
        notebooksModal: "#notebooks-modal",
        noteModal: "#note-modal",
        cellPhoneModal: "#cellphone-modal",
        socialShareModal: "#socialshare-modal",
        signInModal: "#signin-modal",
        pdqManagerModal: "#pdq-manager-modal",
        leadValidCellModal: "#lead-validcellphone-modal"
    };

    Modals.NotebooksModal = function (headers) { PopulateNotebooks(headers); return new CincModal(ids.notebooksModal); };
    Modals.NoteModal = function () { return new CincModal(ids.noteModal); };
    Modals.CellPhoneModal = function () { return new CincModal(ids.cellPhoneModal); };
    Modals.SocialShareModal = function () { return new CincModal(ids.socialShareModal); };
    Modals.Loading = function (text) { $('.js-loadingmodaltext').html(text); return new CincModal(ids.loadingModal); };
    Modals.SignInModal = function () { return new CincModal(ids.signInModal); };
    Modals.PdqManagerModal = function () { return new CincModal(ids.pdqManagerModal); };
    Modals.LeadValidCellModal = function () { return new CincModal(ids.leadValidCellModal); };
    Modals.HomeValuationModal = function () { return new CincModal(ids.homeValuationModal); };

    $('body').on('change', '.modal-state', function () {
        $('body').toggleClass('modal-open', $(this).prop('checked'));
    });

    $('form.js-nosubmit').on('submit', function (e) {
        e.preventDefault();
    });

    Modals.CloseAll = function () {
        $.each(ids, function (i, id) {
            new CincModal(id).Close();
        });
    };

    Modals.AnyOpen = function () {
        for (entryName in ids) {
            if ($(ids[entryName]).is(':checked')) return true;
        }

        return false;
    };

    // use this method to set the response during modal interaction.
    // for example:
    //     onsubmit = "Modals.SetModalResponse(this);"
    //     $(selector).on('click', function () { Modals.SetModalResponse(this, 'response value'); });
    Modals.SetModalResponse = function (element, response) {
        var modalState = $(element).closest('div.modal').children('input.modal-state');

        if (typeof response === 'undefined')
            response = $(element).serializeObject();

        modalState.attr('data-modalresponse', JSON.stringify(response));
        modalState.prop('checked', '');
        modalState.trigger('change');
    };

    var CincModal = Modals.CincModal = function (id) {
        this.Element = $(id);

        this.Open = function () {
            this.Element.removeAttr('data-modalresponse');
            this.Element.prop('checked', true).trigger('change');
            return this;
        };

        this.Close = function () {
            if (!this.IsOpen()) return;

            this.Element.removeAttr('data-modalresponse');
            this.Element.prop('checked', false);
            this.Element.trigger('change');
        };

        this.CloseWithResponse = function (response) {
            if (!this.IsOpen()) return;
            Modals.SetModalResponse(this.Element, response);
        };

        this.IsOpen = function () {
            return this.Element.is(':checked');
        };

        this.Exists = function () {
            return this.Element.length > 0;
        };

        this.Response = function (callback) {
            this.Element.one('change', function (e) {
                var modalResponse = GetModalResponse(e);
                if (typeof modalResponse !== 'undefined')
                    callback(modalResponse, this);
            });
            return this;
        };

        this.ResponseOf = function (response, callback) {
            this.Element.one('change', function (e) {
                var modalResponse = GetModalResponse(e);
                if (modalResponse === response) callback();
            });
            return this;
        };

        this.NoResponse = function (callback) {
            this.Element.one('change', function (e) {
                var modalResponse = GetModalResponse(e);
                if (typeof modalResponse === 'undefined') callback();
            });
            return this;
        };

        var GetModalResponse = function (e) {
            var modalResponse = $(e.target).attr('data-modalresponse');
            if (typeof modalResponse === 'undefined') return modalResponse;

            try {
                return JSON.parse(modalResponse);
            } catch (ex) {
                return modalResponse;
            }
        };
    };

    var PopulateNotebooks = function (headers) {
        var notebooksTemplate = Handlebars.compile($('#notebooksmodal_tmpl').html());
        var notebooksHtml = notebooksTemplate({ headers: headers });
        $('#notebooksmodal-list').html(notebooksHtml);
    };

})(Modals);
