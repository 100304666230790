CINC = (typeof CINC === 'undefined' ? {} : CINC);
CINC.Core = (typeof CINC.Core === 'undefined' ? {} : CINC.Core);
CINC.Core.Cookies = (typeof CINC.Core.Cookies === 'undefined' ? {} : CINC.Core.Cookies);

(function (ga) {
    $(document).ready(function() {

        $('.app-banner > .close').on('click', function (e) {
            e.preventDefault();
            CINC.Core.Cookies.Set('hideAppBanner', 1, 1);
            $('.app-banner').hide();
        });
        $('.app-banner > a').on('click', function (e) {
        });
    });
})(typeof ga === 'undefined' ? function () { } : ga);