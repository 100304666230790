var toastr = (typeof toastr === 'undefined' ? { clear: function () { }, success: function () { }, warning: function () { }, info: function () { }, error: function () { },} : toastr); 
var Notification = {
    CloseAll: function () { toastr.clear(); $.prompt.close(); },
    Toast: {
        Success: function (message, title) { toastr.success(message, title); },
        Info: function (message, title) { toastr.info(message, title); },
        Warning: function (message, title) { toastr.warning(message, title); },
        Error: function (message, title) { toastr.error(message, title); }
    },
    Banner: {
        DefaultSettings: {
            closeButton: true,
            positionClass: "toast-top-full-width",
            preventDuplicates: true,
            showMethod: "slideDown",
            Merge: function (options) { return $.extend({}, this, options); }
        },
        GetDefaultSettings: function () {
            var settings = Notification.Banner.DefaultSettings;
            if (Modals.AnyOpen())
                settings.positionClass = "toast-top-full-width toast-top-full-width-modal";
            return settings;
        },
        Success: function (message, title, options) { toastr.success(message, title, Notification.Banner.GetDefaultSettings().Merge(options)); },
        Info: function (message, title, options) { toastr.info(message, title, Notification.Banner.GetDefaultSettings().Merge(options)); },
        Warning: function (message, title, options) { toastr.warning(message, title, Notification.Banner.GetDefaultSettings().Merge(options)); },
        Error: function (message, title, options) { toastr.error(message, title, Notification.Banner.GetDefaultSettings().Merge(options)); }
    },
    Prompt: {
        DefaultConfirmSettings: {
            buttons: { "OK": true, "Cancel": false },
            Merge: function (options) { return $.extend({}, this, options); }
        },
        Confirm: function (message, title, options) { options = (options || {}); options.title = title; return $.prompt(message, Notification.Prompt.DefaultConfirmSettings.Merge(options)); },
        Show: function (message, title, options) { options = (options || {}); options.title = title; options.buttons = { "OK": true }; return $.prompt(message, Notification.Prompt.DefaultConfirmSettings.Merge(options)); }
    },
    Tooltip: {
        DefaultSettings: {
            Merge: function (options) { return $.extend({}, this, options); }
        },
        Show: function (message, element, options) {
            options = (options || {});
            options.content = message;
            options.trigger = 'custom';
            options.timer = 3000;
            options.focusElement = true;

            if ($(element).hasClass('tooltipstered'))
                $(element).tooltipster('destroy');

            $(element).tooltipster(Notification.Tooltip.DefaultSettings.Merge(options));
            if (options.focusElement == true)
                element.focus();

            return $(element).tooltipster('show');
        },
        CloseAll: function () { $('.tooltipstered').tooltipster('hide'); }
    }
};