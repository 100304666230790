var conversionWriter = (function() {
    return {
        write: function(value) {
            if (value !== 'undefined' && value.length > 0 && value === 'registered' && document.getElementById('conversion-tracking-scripts') === null) {

                $('body').append('<div id="conversion-tracking-scripts">' + CINC.Site.ConversionTrackingScripts + '</div>');
                mixpanel.track('Google Adwords conversion tracking script written');
            } else {
            }
        }
    };
})(typeof ga === 'undefined' ? function () { console.error('ga function could not be found.'); } : ga,
    typeof mixpanel === 'object' ? mixpanel : function () { console.error('mixpanel object could not be found.'); });

CINC = (typeof CINC === 'undefined' ? {} : CINC);
CINC.Core = (typeof CINC.Core === 'undefined' ? {} : CINC.Core);
CINC.Core.CookiesVanilla = (typeof CINC.Core.CookiesVanilla === 'undefined' ? {} : CINC.Core.CookiesVanilla);
CINC.Core.WriteConversionScripts = function(){
    var cookie = CINC.Core.CookiesVanilla.Get('registration-state');
    conversionWriter.write(cookie);
}