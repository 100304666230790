CINC = (typeof CINC === 'undefined' ? {} : CINC);
CINC.Integrations = (typeof CINC.Integrations === 'undefined' ? {} : CINC.Integrations);
CINC.Integrations.Facebook = (typeof CINC.Integrations.Facebook === 'undefined' ? {} : CINC.Integrations.Facebook);
CINC.Integrations.Google = (typeof CINC.Integrations.Google === 'undefined' ? {} : CINC.Integrations.Google);

// Instead of running the risk of breaking the HousesDotNet.Account.js file
// Pulling out all login and sign up event handling to a single file devoted to the devil.
// Account settings and file uploading should not be involved with the Devil
(function (ga) {
    $(document).ready(function () {
        
        $('.toggleSignup').on('click', function (e) {
            e.preventDefault();
            if ($('.js-signinform').is(':visible')) {
                $('.js-signupform').show();
                $('.js-signinform').hide();
                if (typeof ga !== 'undefined') {
                }
                var socialButtons = $('.js-socialsignin-buttons');
                if (socialButtons.hasClass('hidden')) {
                    socialButtons.removeClass('hidden');
                    socialButtons.find('iframe[data-src]').each(function () {
                        $(this).attr('src', $(this).attr('data-src'));
                        $(this).attr('data-src', null);
                    });
                }
            }
            else {
                $('.js-signinform').show();
                $('.js-signupform').hide();
            }
        });

        $(".js-logout").on('click', function (e) {
            e.preventDefault()
            CINC.Api.Account('logout')
                .post()
                .success(function (result) {
                    window.location = '/';
                })
                .fail(function (f) {
                });
        });

        $(".js-promptforlogin").on('click', function (e) {
            e.preventDefault();
            PromptForLogin();
        });

        $(".js-promptforsignup").on('click', function (e) {
            e.preventDefault();
            PromptForSignup();
        });

        $("form.js-loginform").on('submit', function (e) {
            e.preventDefault();
            var data = $(this).serializeObject();
            //remove leading or trailing whitespace because we are assuming that the whitespace was unintentional
            if (data.Email) data.Email = data.Email.trim();
            if (!data.Email || !isEmail(data.Email)) {
                var emailElement = $(this).find("[name=Email]");
                Notification.Tooltip.Show("Please enter a valid email", emailElement);
                return;
            }

            CINC.Registration.closeSignInModals('Sign In');
            data.Timezone = CINC.currentTimezone();
            data.TimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
            CINC.Api.Account('login')
                .post(data)
                .success(function (result) {
                    if (result.isSuccess) {
                        if (result.userInfo && result.userInfo.securityLevel >= 10 && result.userInfo.securityLevel < 25 && _.isString(result.userInfo.dashboardUrl)) {
                            window.location = result.userInfo.dashboardUrl;
                        } else {         
                            window.location.reload();                                      
                        }
                    } else {
                        PromptForLogin("Invalid Login", true);
                        Notification.Banner.Error("Click here if you need help getting back into your account.", "Invalid Login", { onclick: function () { window.location = '/account/forgotpassword'; } });
                    }
                })
                .fail(function (f) { Notification.Banner.Error(f.responseText, "Login Error"); PromptForLogin("Login Failed", true); });
        });

        function createFunctionWithTimeout(callback, opt_timeout) {
            var called = false;
            function fn() {
                if (!called) {
                    called = true;
                    callback();
                }
            } setTimeout(fn, 1000);
            return fn;
        }

        /* Signup Form Step 1 (main) */
        $('.js-signupform-form').on('submit', function (e) {
            e.preventDefault();

            var $form = $(this);
            var data = $form.serializeObject();
            if (_.isObject(CINC.Property)) {
                data.price = CINC.Property.price;
                data.city = CINC.Property.city;
                data.state = CINC.Property.state;
                data.zip = CINC.Property.zip;
            }
            var searchTermsAgree = $form.find('.js-searchtermsagree');
            if (searchTermsAgree.length === 1 && !searchTermsAgree.prop('checked')) {
                Notification.Tooltip.Show("Please accept the terms and conditions", $form.find('.js-searchtermsagree'));
                return;
            }
            
            var subscribeTextingAgree = $form.find('.js-subscribeTexting');
            if ((subscribeTextingAgree.length === 0 ) || (subscribeTextingAgree.length === 1 && subscribeTextingAgree.prop('checked'))) {
                //if this checkbox doesnt exist we want to assume they want texts
                //this will set the Member Database field UnSubscribed_Text to False
                data.IsSubscribedToText = true;
            }

            data.FirstName = $.trim(data.FirstName || '');
            if (!data.FirstName || data.FirstName.length === 0) {
                Notification.Tooltip.Show("Please enter your name", $form.find('[name="FirstName"]'));
                return;
            }

            var firstLast = data.FirstName.split(" ");
            if (firstLast.length < 1 || (firstLast.length === 1 && !CINC.Site.Registration_AllowPartialName)) {
                Notification.Tooltip.Show("Please enter your full name", $form.find('[name="FirstName"]'));
                return;
            }

            for (var i = 0; i < firstLast.length; i++) {
                if (firstLast[i].length === 0) continue;

                firstLast[i] = firstLast[i].replace(/[^a-zA-Z]+/g, '');
                if (firstLast[i].length === 0) {
                    Notification.Tooltip.Show("Please enter a valid name", $form.find('[name="FirstName"]'));
                    return;
                }
            }

            data.Email = $.trim(data.Email || '');
            if (!data.Email || !isEmail(data.Email)) {
                Notification.Tooltip.Show("Please enter a valid email", $form.find('[name="Email"]'));
                return;
            }

            var phoneInputElement = $form.find('[name="Phone"]');
            if (!CINC.Registration.isValid.phoneInputValue(phoneInputElement, data.Phone)) {
                return;
            }

            var $password = $form.find('[name="Password"]');
            if ($password.length === 1) {
                if (!data.Password || data.Password.length === 0) {
                    Notification.Tooltip.Show("Please enter a password", $password);
                    return;
                }
            }
            else if (phoneInputElement.length === 1) {
                data.Password = data.Phone;
            }
            
            data.Timezone = CINC.currentTimezone();
            data.TimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

            CINC.Registration.closeSignInModals('Create Account');
            var loadingModal = Modals.Loading("Signing up now! Give us a second to get you all set up.").Open();

            CINC.Core.CookiesVanilla.Set('registration-state', 'unregistered', 5);
            CINC.Api.Account('register')
                .post(data)
                .success(function (result) {
                    if (result.isSuccess) {
                        //fullstory
                        if (typeof FS !== "undefined" && FS !== null){
                            FS.event('buyer registered', {
                                pageName_str: 'Registration Model',
                                leadType_str: 'buyer'
                            });
                        }

                        if (window.report_conversion) {
                            window.report_conversion();
                        }

                        CINC.Registration.callbacks.registrationSuccess(result);
                    } else {
                        CINC.Registration.callbacks.registrationError(result, loadingModal);
                    }
                })
                .fail(function (f) {
                    CINC.Registration.callbacks.registrationApiFailure(f, loadingModal);
                })
                .always(function() {
                });

            return false;
        });

        /* Signup Form Step 1.5 (Phone only -- potential 3rd party sign in flow) */
        $('.js-signupform-required-phone').on('submit', function (e) {
            e.preventDefault();

            var $form = $(this);
            var data = $form.serializeObject();
            var phoneInputElement = $form.find('[name="Phone"]');

            if (!CINC.Registration.isValid.phoneInputValue(phoneInputElement, data.Phone)) {
                return;
            }

            // Set up of 3rd party (Facebook or Google) vars
            var signupContext = CINC.Integrations.Facebook.Auth ? CINC.Api.Account('facebooksignin') : CINC.Api.AccountSocialSign('google');
            var signupPayload = CINC.Integrations.Facebook.Auth || CINC.Integrations.Google.User;
            signupPayload.phoneNumber = data.Phone; // Add phone number obtained to payload

            CINC.Registration.closeSignInModals('Phone Number Registration');
            var loadingModal = Modals.Loading("Signing up now! Give us a second to get you all set up.").Open();
            signupContext.post(signupPayload)
                .success(function (result) {
                    if (result.isSuccess) {
                        CINC.Registration.callbacks.registrationSuccess(result);
                    } else {
                        CINC.Registration.callbacks.registrationError(result, loadingModal);
                    }
                })
                .fail(function (f) {
                    CINC.Registration.callbacks.registrationApiFailure(f, loadingModal);
                });
        });

        /* Signup Form Step 2 (Extra Reg) */
        $('.js-signupform-extra').on('submit', function (e) {
            e.preventDefault();

            var $form = $(this);
            var data = $form.serializeObject();
            var fields = ['Prequalified', 'FirstTimeBuyer', 'TimeframeToBuy', 'HouseToSell'];
            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                if (!data.hasOwnProperty(field)) {
                    Notification.Tooltip.Show('Please fill out the fields so we can better assist in your home search', $form.find('[name="' + field + '"]').first());
                    return;
                }
            }
            if (data.HouseToSell === 'true') {
                CINC.User.houseToSell = true;
            }
            if (data.HouseToSell === 'false') {
            }

            CINC.Registration.closeSignInModals('Create Account');
            var loadingModal = Modals.Loading('').Open();
            CINC.Api.Account('register/extra')
                .post(data)
                .success(function (result) {
                    //fullstory
                    if (typeof FS !== "undefined" && FS !== null){
                        FS.event('registration questions confirmed', {
                            pageName_str: 'Registration Model',
                            leadType_str: 'buyer'
                        });
                    }


                    if (CINC.User.houseToSell) {
                        PromptForSignup('Submission Failed', false, '3a');
                        var standAloneAutoComplete = document.getElementById('autocomplete-claim-your-home');
                        standAloneAutoComplete.focus();
                    }
                    else if (CINC.Site.On_DiscQuestionaire) {
                        PromptForSignup('', false, '3');
                    } else {
                        CINC.Registration.finishRegistration();
                    }
                })
                .fail(function (f) {
                    Notification.Banner.Error(f.responseText, "Submission Error");
                    PromptForSignup('Submission Failed', false, '2');
                });

            return false;
        });

        /* Signup Form Step 3 (DISC) */
        $('.js-signupform-disc').on('submit', function (e) {
            e.preventDefault();

            var $form = $(this);

            var $radio = $form.find('[name="DiscType"]:checked');
            if ($radio.length === 0) {
                Notification.Tooltip.Show('Please fill out the fields so we can better assist in your home search', $form.find('[name="DiscType"]').first());
                return;
            }

            var data = {
                DiscType: $radio.val(),
                DiscAnswer: $radio.next('label').text()
            };

            CINC.Registration.closeSignInModals('Create Account');
            var loadingModal = Modals.Loading('').Open();
            CINC.Api.Account('register/disc')
                .post(data)
                .success(function (result) {
                    CINC.Registration.finishRegistration();
                })
                .fail(function (f) {
                    Notification.Banner.Error(f.responseText, "Submission Error");
                    PromptForSignup('Submission Failed', false, '3');
                });
            return false;
        });

        /* Signup Form Step 3a (Seller) */

        var standAloneAutoComplete = document.getElementById('autocomplete-claim-your-home');

        if (standAloneAutoComplete != null && standAloneAutoComplete && !CINC.Core.IsBot()) {
            var streetAddress = $('#claim-your-home-street-address');
            var streetNumber = $('#claim-your-home-street-number');
            var streetName = $('#claim-your-home-street-name');
            var city = $('#claim-your-home-city');
            var state = $('#claim-your-home-state');
            var zip = $('#claim-your-home-zip');
            var lat = $('#claim-your-home-lat');
            var long = $('#claim-your-home-long');
            initAutoComplete('autocomplete-claim-your-home');
        }

        //stand-alone autocomplete for Step 3a modal
        function initAutoComplete(autoCompleteId) {
            var input = document.getElementById(autoCompleteId);
            var _addEventListener = input.addEventListener
                ? input.addEventListener
                : input.attachEvent;
            function addEventListenerWrapper(type, listener) {
                // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
                // and then trigger the original listener.
                var orig_listener = listener;
                listener = function (event) {
                    var suggestion_selected =
                        document.getElementsByClassName("pac-item-selected").length > 0;
                    if (event.which == 13 && !suggestion_selected) {
                        input.dispatchEvent(new CustomEvent('enterKeyPressedWithSelectedProperty'));
                        var simulatedEvent = document.createEvent("Event");
                        simulatedEvent.keyCode = 40;
                        simulatedEvent.which = 40;
                        orig_listener.apply(input, [simulatedEvent]);
                    }
                    orig_listener.apply(input, [event]);
                };

                _addEventListener.apply(input, [type, listener]);
            }

            input.addEventListener = addEventListenerWrapper;
            input.attachEvent = addEventListenerWrapper;
            
            var autocomplete = new google.maps.places.Autocomplete(document.getElementById(autoCompleteId),
                { types: ['address'], componentRestrictions: { country: ['us', 'ca'] } });
            autocomplete.setFields(["address_components",
                "adr_address",
                "alt_id",
                "formatted_address",
                "geometry",
                "icon",
                "id",
                "name",
                "photo",
                "place_id",
                "scope",
                "type",
                "url",
                "vicinity"]);

            function formatResult(place) {
                var ADDRESS_COMPONENTS = {
                    subpremise: "short_name",
                    street_number: "short_name",
                    route: "long_name",
                    locality: "long_name",
                    sublocality_level_1: "long_name",
                    neighborhood: "long_name",
                    administrative_area_level_1: "short_name",
                    administrative_area_level_2: "long_name",
                    country: "long_name",
                    postal_code: "short_name",
                };
                var returnData = {};
                // Loop through google's address component object
                for (var i = 0; i < place.address_components.length; i++) {
                    // Look through the list of types for a given component
                    // Each component can have multiple types, and the first in the list is not always the one we're keying on
                    for (var j = 0; j < place.address_components[i].types.length; j++) {
                        var addressType = place.address_components[i].types[j];
                        if (ADDRESS_COMPONENTS[addressType]) {
                            var val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
                            returnData[addressType] = val;
                            break;
                        }
                    }
                }
                returnData["latitude"] = place.geometry.location.lat();
                returnData["longitude"] = place.geometry.location.lng();
                return returnData;
            }

            function parseAddress(address) {
                if (address === null) return null;
                return {
                    streetAddress: address.street_number
                        ? address.street_number + ' ' + address.route
                        : address.route,
                    streetNumber: address.street_number,
                    streetName: address.route,
                    city: address.locality || address.sublocality_level_1 || address.neighborhood || "",
                    state: address.administrative_area_level_1 || "",
                    zip: address.postal_code || "00000",
                    latitude: address.latitude,
                    longitude: address.longitude
                };
            }

            autocomplete.addListener("place_changed", function () {
                const autocompleteElement = document.getElementById('autocomplete-claim-your-home');
                const GoogleMapsGeocoder = new google.maps.Geocoder();
                
                GoogleMapsGeocoder.geocode({ 'address': autocompleteElement.value }, function(results, status) {
                  if (status == google.maps.GeocoderStatus.OK)
                  {
                      const place = results[0];

                      if (!place.geometry) {
                          // User entered the name of a Place that was not suggested and
                          // pressed the Enter key, or the Google Geocoder request failed.
                          Notification.Tooltip.Show('Please enter a valid address', autocompleteField);
                          return;
                      }
                      
                      const resultingObject = formatResult(place);
                      const parsedAddress = parseAddress(resultingObject);
                      if (parsedAddress) {
                          if (streetNumber) {
                              streetNumber.attr('value', parsedAddress.streetNumber);
                          }
                          if (streetName) {
                              streetName.attr('value', parsedAddress.streetName);
                          }
                          if (streetAddress) {
                              streetAddress.attr('value', parsedAddress.streetAddress);
                          }
                          if (city)
                              city.attr('value', parsedAddress.city);
                          if (state) {
                              state.attr('value', parsedAddress.state);
                          }
                          if (zip) {
                              zip.attr('value', parsedAddress.zip);
                          }
                          if (lat) {
                              lat.attr('value', parsedAddress.latitude);
                          }
                          if (long) {
                              long.attr('value', parsedAddress.longitude);
                          }
                      }
                  }
                  else
                  {
                      Notification.Tooltip.Show('Please enter a valid address', autocompleteElement);
                      return;
                  }
                });
             
            });
        }

        $('.js-signupform-seller').on('submit', function (e) {
            e.preventDefault();

            var submittingStatusElement = $('#claim-your-home-submitting');
            var submittingStatus = submittingStatusElement[0].value;
            if (submittingStatus) {
                // Don't allow the ENTER key to spam requests.
                return false;
            }

            // Check hidden inputs for valid data to prevent form from attempting to submit before it has data
            // (this fixes a bug where an invalid request would be sent to the server)
            // (this also fixes the behavior where the form submits the first time the user presses ENTER)
            var hiddenStreetAddress = $('#claim-your-home-street-address')[0].value;
            if (!hiddenStreetAddress) {
                return false;
            }

            var $form = $(this);
            var data = $form.serializeObject();

            if (data.hasOwnProperty('Address') || $.trim(data['Address']).length > 0) {
                //This regex accepts minimum three character and there is no limit on max characters.Characters may include a - z, A - Z alphabets, whitespace, comma(,), dot(.), apostrophe('), and dash(-) symbols.
                var addressRGEX = new RegExp(/^[a-zA-Z0-9\s,.'-]{3,}$/);
                var validAddress = addressRGEX.test(data['Address']);
                if (!validAddress) {
                    Notification.Tooltip.Show('Please enter a valid address', $form.find('[name="Address"]').first());
                    return;
                }
            } else {
                Notification.Tooltip.Show('Please fill out the fields so we can better assist in your home search', $form.find('[name="Address"]').first());
                return;
            }

            if (data.hasOwnProperty('UnitNumber') || $.trim(data['UnitNumber']).length > 0) {
                //Characters may include a - z, A - Z alphabets, whitespace, comma(,), dot(.), apostrophe('), and dash(-) symbols.
                var unitRGEX = /^[a-zA-Z0-9\s,.'-]*$/;

                var validUnit = unitRGEX.test(data['UnitNumber']);
                if (!validUnit) {
                    Notification.Tooltip.Show('Please enter a valid unit number', $form.find('[name="UnitNumber"]').first());
                    return;
                }
            }

            submittingStatusElement.val('submitting');
            
            CINC.Registration.closeSignInModals('Create Account');
            var loadingModal = Modals.Loading('').Open();

            //create a homepulse session
            var apiUrl;
            if (CINC.InDev || CINC.InStaging) {// use UAT endpoint
                apiUrl = "https://uat.consumer-seller.cincapi.com/api/sessionexternal";
            } else {// use PROD endpoint
                apiUrl = "https://consumer-seller.cincapi.com/api/sessionexternal";
            }

            var payload =
            {
                "streetAddress": data['StreetAddress'],
                "city": data['City'],
                "state": data['State'],
                "zip": data['Zip'],
                "longitude": parseFloat(data['Longitude']),
                "latitude": parseFloat(data['Latitude']),
                "unit": data['UnitNumber'],
                "memberId": CINC.User.mdid
            };

            $.ajax({
                url: apiUrl,
                type: "POST",
                headers: { "x-domain-document-id": CINC.Site.DDID },
                processData: false,
                contentType: 'application/json',
                data: JSON.stringify(payload),
                success: function (result) {
                    //fullstory
                    if (typeof FS !== "undefined" && FS !== null){
                        FS.event('buyer registration homepulse claimed', {
                            pageName_str: 'Registration Model',
                            leadType_str: 'buyer'
                        });
                    }
                    if (CINC.Site.On_DiscQuestionaire) {
                        PromptForSignup('', false, '3');
                    } else {
                        CINC.Registration.finishRegistration();
                    }
                },
                failure: function (f) {
                    Notification.Banner.Error(f.responseText, "Submission Error");
                    PromptForSignup('Submission Failed', false, '3a');
                }
            });            
        });

        $('.js-signupform-seller-close').on('click', function () {
            if (CINC.Site.On_DiscQuestionaire) {
                PromptForSignup('', false, '3');
            } else {
                CINC.Registration.finishRegistration();
            }
        });

        function continueFacebookSignup(facebookProfile) {
            CINC.Registration.closeSignInModals('Log In With Facebook');
            CINC.Integrations.Facebook.Auth = facebookProfile.authResponse;

            CINC.Core.CookiesVanilla.Set('registration-state', 'unregistered', 5);

            if (CINC.Core.CookiesVanilla.Get('fbprompt') === '1' || (CINC.QS && CINC.QS['fbprompt'] === '1')) {
                var phoneInputElement = $('.js-signupform-required-phone').find('[name="Phone"]');
                phoneInputElement.val('123456789012345');
                $('.js-signupform-required-phone').trigger('submit');
            }
            else {
                if (CINC.Site.Registration_SocialWithoutPhone) {
                    var signupContext = CINC.Api.Account('facebooksignin');
                    var signupPayload = CINC.Integrations.Facebook.Auth;
                    var loadingModal = Modals.Loading("Signing up now! Give us a second to get you all set up.").Open();
                    signupContext.post(signupPayload).success(function(result) {
                        if (!result.isSuccess) {
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track('User Partial Registration Failed with Facebook');
                            }
                            CINC.Registration.callbacks.registrationError(result, loadingModal);
                        } else {
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track('User Partial Registration Succeeded with Facebook');
                            }
                            PromptForSignup('', false, '1-phone');
                        }
                        })
                        .fail(function (f) {
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track('User Partial Registration Failed with Facebook');
                            }
                            CINC.Registration.callbacks.registrationApiFailure(f, loadingModal);
                        });;
                } else {
                    PromptForSignup('', false, '1-phone');
                }
            }
        }

        /* Signup Form For Home Valuation Page */
        $('.js-signupform-home-val-form').on('submit', function (e) {
            e.preventDefault();

            var $form = $(this);
            var data = $form.serializeObject();
            data.FullName = $.trim(data.FullName || '');
            if (!data.FullName || data.FullName.length === 0) {
                Notification.Tooltip.Show("Please enter your name", $form.find('[name="FullName"]'));
                return;
            }

            var firstLast = data.FullName.split(" ");
            if (firstLast.length < 1 || (firstLast.length === 1 && !CINC.Site.Registration_AllowPartialName)) {
                Notification.Tooltip.Show("Please enter your full name", $form.find('[name="FullName"]'));
                return;
            }

            for (var i = 0; i < firstLast.length; i++) {
                if (firstLast[i].length === 0) continue;

                firstLast[i] = firstLast[i].replace(/[^a-zA-Z]+/g, '');
                if (firstLast[i].length === 0) {
                    Notification.Tooltip.Show("Please enter a valid name", $form.find('[name="FullName"]'));
                    return;
                }
            }

            data.Email = $.trim(data.Email || '');
            if (!data.Email || !isEmail(data.Email)) {
                Notification.Tooltip.Show("Please enter a valid email", $form.find('[name="Email"]'));
                return;
            }

            var phoneInputElement = $form.find('[name="PhoneNumber"]');
            if (!CINC.Registration.isValid.phoneInputValue(phoneInputElement, data.PhoneNumber)) {
                return;
            }

            var $password = $form.find('[name="Password"]');
            if ($password.length === 1) {
                if (!data.Password || data.Password.length === 0) {
                    Notification.Tooltip.Show("Please enter a password", $password);
                    return;
                }
            }
            else if (phoneInputElement.length === 1) {
                data.Password = data.PhoneNumber;
            }

            var fields = ['PurposeForRequest', 'Timeframe'];
            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                if (!data.hasOwnProperty(field)) {
                    Notification.Tooltip.Show('Please fill out the fields so we can better assist in your home search', $form.find('[name="' + field + '"]').first());
                    return;
                }
                if (data[field] === null || data[field] === '' || data[field].toLowerCase().indexOf("select") !== -1) {
                    Notification.Tooltip.Show('Please fill out the fields so we can better assist in your home search', $form.find('[name="' + field + '"]').first());
                    return;
                }
            }

            data.Timezone = CINC.currentTimezone();
            data.TimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

            CINC.Registration.closeSignInModals('Create Account');
            var loadingModal = Modals.Loading("Signing up now! Give us a second to get you all set up.").Open();

            CINC.Core.CookiesVanilla.Set('registration-state', 'unregistered', 5);

            var urlPath = window.location.pathname;
            var pathArray = urlPath.split('/');
            var customPath = pathArray[2];
            $.ajax({
                method: 'POST',
                url: '/api/homevalue/'+ customPath + '/register',
                data: data
            })
            .done(function(result) {
                CINC.Registration.finishRegistration(false);
                window.location.replace(result.destinationPath);
            })
            .fail(function(jqXHR, textStatus, errorThrown){
                CINC.Registration.callbacks.registrationApiFailure(jqXHR, loadingModal);
            });

            return false;
        });

        /* Signup Form with Questions For Home Valuation Page */
        $('.js-question-home-val-form').on('submit', function (e) {
            e.preventDefault();

            var $form = $(this);
            var data = $form.serializeObject();


            var fields = ['PurposeForRequest', 'Timeframe'];
            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                if (!data.hasOwnProperty(field)) {
                    Notification.Tooltip.Show('Please fill out the fields so we can better assist in your home search', $form.find('[name="' + field + '"]').first());
                    return;
                }
                if (data[field] === null || data[field] === '' || data[field].toLowerCase().indexOf("select") !== -1) {
                    Notification.Tooltip.Show('Please fill out the fields so we can better assist in your home search', $form.find('[name="' + field + '"]').first());
                    return;
                }
            }

            data.Timezone = CINC.currentTimezone();
            data.TimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

            var urlPath = window.location.pathname;
            var pathArray = urlPath.split('/');
            var customPath = pathArray[2];
            $.ajax({
                method: 'POST',
                url: '/api/homevalue/' + customPath + '/register',
                data: data
            })
                .done(function (result) {
                    CINC.Registration.finishRegistration(false);
                    window.location.replace(result.destinationPath);
                })
                .fail(function (jqXHR, textStatus, errorThrown) {
                    CINC.Registration.callbacks.registrationApiFailure(jqXHR, loadingModal);
                });
            return false;
        });

        /* Login Form For Home Valuation Page */
        $("form.js-loginform-home-val-form").on('submit', function (e) {
            e.preventDefault();
            var $form = $(this);
            var data = $form.serializeObject();
            //remove leading or trailing whitespace because we are assuming that the whitespace was unintentional
            if (data.Email) data.Email = data.Email.trim();
            if (!data.Email || !isEmail(data.Email)) {
                var emailElement = $form.find("[name=Email]");
                Notification.Tooltip.Show("Please enter a valid email", emailElement);
                return;
            }

            var fields = ['PurposeForRequest', 'Timeframe'];
            for (var i = 0; i < fields.length; i++) {
                var field = fields[i];
                if (!data.hasOwnProperty(field)) {
                    Notification.Tooltip.Show('Please fill out the fields so we can better assist in your home search', $form.find('[name="' + field + '"]').first());
                    return;
                }
                if (data[field] === null || data[field] === '' || data[field].toLowerCase().indexOf("select") !== -1) {
                    Notification.Tooltip.Show('Please fill out the fields so we can better assist in your home search', $form.find('[name="' + field + '"]').first());
                    return;
                }
            }

            CINC.Registration.closeSignInModals('Sign In');
            data.Timezone = CINC.currentTimezone();
            data.TimezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

            var loadingModal = Modals.Loading('').Open();

            CINC.LoginUser.loginUser(data);
        });

        function continueGoogleSignup(googleProfile) {
            CINC.Registration.closeSignInModals('Create Account');
            CINC.Integrations.Google.User = googleProfile;
            CINC.Core.CookiesVanilla.Set('registration-state', 'unregistered', 5);

            if (googleProfile.phoneNumber == null || googleProfile.phoneNumber === '') {
                if (CINC.Site.Registration_SocialWithoutPhone) {
                    var signupContext = CINC.Api.AccountSocialSign('google');
                    var loading = Modals.Loading("Signing up now! Give us a second to get you all set up.").Open();
                    signupContext.post(googleProfile).success(function(result) {
                        if (!result.isSuccess) {
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track('User Partial Registration Failed with Google');
                            }
                            CINC.Registration.callbacks.registrationError(result, loading);
                        } else {
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track('User Partial Registration Successful with Google');
                            }
                            PromptForSignup('', false, '1-phone');
                        }
                        })
                        .fail(function (f) {
                            if (typeof mixpanel !== 'undefined') {
                                mixpanel.track('User Partial Registration Failed with Google');
                            }
                            CINC.Registration.callbacks.registrationApiFailure(f, loading);
                        });;
                } else {
                    PromptForSignup('', false, '1-phone');
                }
            } else {
                var loadingModal = Modals.Loading("Signing up now! Give us a second to get you all set up.").Open();
                CINC.Api.AccountSocialSign('google').post(googleProfile)
                    .success(function (result) {
                        if (result.isSuccess) {
                            CINC.Registration.callbacks.registrationSuccess(result);
                        } else {
                            CINC.Registration.callbacks.registrationError(result, loadingModal);
                        }
                    })
                    .fail(function (f) {
                        CINC.Registration.callbacks.registrationApiFailure(f, loadingModal);
                    });
            }
        }


        window.onmessage = function (e) {
            if (e.data === 'google-signin-fail') {
                Notification.Banner.Error(
                    'Sorry! We are unable to retrieve your Google profile information. Please register by entering your information in the form.',
                    'Unable to Get Google Profile');
            } else if (e.data === 'facebook-signin-fail') {
                Notification.Banner.Error(
                    'Sorry! We are unable to retrieve your Facebook profile information. Please register by entering your information in the form.',
                    'Unable to Get Facebook Profile');
            } else if (e.data && e.data.authResponse && e.data.status) {
                continueFacebookSignup(e.data);
            } else if (e.data && e.data.familyName && e.data.givenName && e.data.email) {
                continueGoogleSignup(e.data);
            }
        };
    });
})(typeof ga === 'undefined' ? function(){} : ga);