CINC = (typeof CINC === 'undefined' ? {} : CINC);

CINC.LoginUser = (function (ga) {

    var loginUser = function (data) {
        CINC.Api.Account('login')
            .post(data)
            .success(function (result) {
                if (result.isSuccess) {
                    registerUser(data);
                } else {
                    PromptForLogin("Invalid Login", true);
                    Notification.Banner.Error("Click here if you need help getting back into your account.", "Invalid Login", { onclick: function () { window.location = '/account/forgotpassword'; } });
                }
            })
            .fail(function (f) { Notification.Banner.Error(f.responseText, "Login Error"); PromptForLogin("Login Failed", true); });
    };

    var registerUser = function (data) {
        var urlPath = window.location.pathname;
        var pathArray = urlPath.split('/');
        var customPath = pathArray[2];

        $.ajax({
            method: 'POST',
            url: '/api/homevalue/' + customPath + '/register',
            data: data
        })
            .done(function (result) {
                window.location.replace(result.destinationPath);
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                CINC.Registration.callbacks.registrationApiFailure(jqXHR, loadingModal);
            });
    };
    return {
        loginUser: loginUser
    }
})(typeof ga === 'undefined' ? function () { } : ga);