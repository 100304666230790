CINC = (typeof CINC === 'undefined' ? {} : CINC);

(function () {

    CINC.Core = {
        el: {},
        g: {},
        Okta: function (redirecturl) {
            var redirecturl = encodeURIComponent(redirecturl || window.location.href);
                window.location = window.location.origin + "/account/login/admin?redirecturl=" + redirecturl;
        },
        LoadActionHandlers: {
            NotificationBanner: function (payload) {
                Notification.Banner[payload.type](payload.message, payload.title);
            }
        },
        LocalStorage: {
            IsAvailable: (typeof (Storage) !== 'undefined'),
            Get: function (key) {
                if (!CINC.Core.LocalStorage.IsAvailable) return undefined;
                var value = localStorage.getItem(key.toLowerCase());
                if (!value) return undefined;
                if (value[0] === '{' || value[0] === '[') {
                    try {
                        return JSON.parse(value);
                    } catch (e) { }
                }

                return value;
            },
            Set: function (key, value) {
                if (!CINC.Core.LocalStorage.IsAvailable) return;
                if (typeof value === 'object')
                    value = JSON.stringify(value);
                return localStorage.setItem(key.toLowerCase(), value);
            },
            Remove: function (key) {
                if (!CINC.Core.LocalStorage.IsAvailable) return;
                return localStorage.removeItem(key.toLowerCase());
            },
        },
        Cookies: {
            Get: function (key) {
                var value = $.cookie(key.toLowerCase());
                if (value === undefined)
                    return '';
                return value;
            },
            Set: function (key, value, expireDays) {
                var parms = {
                    domain: '.' + CINC.Site.DomainName,
                    expires: expireDays || 30,
                    path: '/'
                };
                $.cookie(key.toLowerCase(), value, parms);
            },
            Remove: function (key) {
                CINC.Core.Cookies.Set(key.toLowerCase(), '', -1);
            }
        },
        CookiesVanilla: {
            Get: function (cname) {
                var name = cname + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            Set: function (cname, cvalue, exdays) {
                // if exdays is not a valid date format, the cookie will be set to expire with the session
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = "expires=" + d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            },
            Remove: function (key) {
                CINC.Core.CookiesVanilla.Set(key, '');
            }
        },

       IsBot: function () {
            return /nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver|bingbot|Google Web Preview|Mediapartners-Google|AdsBot-Google|Baiduspider|Ezooms|YahooSeeker|AltaVista|AVSearch|Mercator|Scooter|InfoSeek|Ultraseek|Lycos|Wget|YandexBot|Yandex|YaDirectFetcher|SiteBot|Exabot|AhrefsBot|MJ12bot|TurnitinBot|magpie-crawler|Nutch Crawler|CMS Crawler|rogerbot|Domnutch|ssearch_bot|XoviBot|netseer|digincore|fr-crawler|wesee|AliasIO/i.test(navigator.userAgent);
        },
        IsMobile: function () {
            return ($(window).width() < 770);
        },
        QueryString: function () {
            // make sure underscore is loaded
            if (typeof _ !== 'function') {
                return {};
            }

            return {
                // The functions ripped from https://github.com/sindresorhus/query-string/blob/master/index.js
                parse: function (str) {
                    if (!_.isString('str')) {
                        return {};
                    }

                    str = decodeURIComponent(str);

                    str = str.trim().replace(/^(\?|#|&)/, '');

                    if (!str) {
                        return {};
                    }

                    return _.reduce(str.split('&'), function (ret, param) {
                        var parts = param.replace(/\+/g, ' ').split('=');
                        var key = parts[0];
                        var val = parts[1];

                        key = decodeURIComponent(key);

                        // missing `=` should be `null`:
                        // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
                        val = val === undefined ? null : decodeURIComponent(val);

                        if (!ret.hasOwnProperty(key)) {
                            ret[key] = val;
                        } else if (_.isArray(ret[key])) {
                            ret[key].push(val);
                        } else {
                            ret[key] = [ret[key], val];
                        }

                        return ret;
                    }, {});
                },
                stringify: function (obj) {
                    if (!obj) {
                        return '';
                    }

                    return _.filter(_.map(_.keys(obj).sort(), function (key) {
                        var val = obj[key];

                        if (_.isArray(val)) {
                            return val.sort().map(function (val2) {
                                return encodeURI(key) + '=' + encodeURIComponent(val2);
                            }).join('&');
                        }

                        return encodeURI(key) + '=' + encodeURIComponent(val);
                    }), function (x) {
                        return x.length > 0;
                    }).join('&');
                },
                remove: function (keyArray) {
                    var qs = CINC.Core.QueryString.parse(window.location.search);
                    for (var i = 0; i < keyArray.length; i++)
                        delete qs[keyArray[i]];

                    return CINC.Core.QueryString.stringify(qs);
                }
            };
        }(),
        EndBecomeUser: function () {
            var $trigger = $(this);
            if (!$trigger.hasClass('loading')) {
                $trigger.addClass('loading');
                CINC.Api.Become(true).del().success(function () {
                    var callbackUrl = $trigger.attr('data-callbackurl');
                    if (callbackUrl.length > 0) {
                        window.location.href = callbackUrl;
                    }
                    else {
                        window.location.reload();
                    }
                }).fail(function () {
                    alert('Error ending become user!');
                });
            }
        },
        GoToCorefact: function () {
            var ajx = CINC.Api.Dashboard.Settings.Corefact('getauthurl');
            ajx.get({})
                .success(function (response) {
                    if (response.ssoUrl) {
                        window.open(response.ssoUrl, '_blank');
                    }
                }).fail(CINC.Dashboard.ShowError);
        },
        GetXPath: function (element) {
            if (!element) return '';
            if (typeof element === 'string') element = $(element);
            if (element instanceof jQuery) {
                if (element.length === 0) return '';
                element = element[0];
            }

            if (element && element.id)
                return '//*[@id="' + element.id + '"]';
            else {
                var paths = [];  // Use nodeName (instead of localName) 
                var idFound = false;

                for (; element && element.nodeType == Node.ELEMENT_NODE && !idFound; element = element.parentNode) {
                    var index = 0;
                    var hasFollowingSiblings = false;
                    for (var sibling = element.previousSibling; sibling; sibling = sibling.previousSibling) {
                        // Ignore document type declaration.
                        if (sibling.nodeType == Node.DOCUMENT_TYPE_NODE)
                            continue;

                        if (sibling.nodeName == element.nodeName)
                            ++index;
                    }

                    for (var sibling = element.nextSibling; sibling && !hasFollowingSiblings; sibling = sibling.nextSibling) {
                        if (sibling.nodeName == element.nodeName)
                            hasFollowingSiblings = true;
                    }

                    var tagName = (element.prefix ? element.prefix + ":" : "") + element.localName;
                    var pathIndex = (index || hasFollowingSiblings ? "[" + (index + 1) + "]" : "");
                    if (element.id) {
                        tagName = '/*[@id="' + element.id + '"]';
                        pathIndex = "";
                        idFound = true;
                    }
                    paths.splice(0, 0, tagName + pathIndex);
                }

                return paths.length ? "/" + paths.join("/") : null;
            }
        },
        GetElementByXPath: function (xpath, context) {
            if (!xpath) return null;

            var doc = (context && context.ownerDocument) || window.document;
            var result = doc.evaluate(xpath, context || doc, null, XPathResult.ANY_TYPE, null);
            switch (result.resultType) {
                case XPathResult.NUMBER_TYPE:
                    return result.numberValue;
                case XPathResult.STRING_TYPE:
                    return result.stringValue;
                case XPathResult.BOOLEAN_TYPE:
                    return result.booleanValue;
                default:
                    var nodes = [];
                    var node;
                    while (node = result.iterateNext())
                        nodes.push(node);
                    return nodes;
            }
        }
    };

    CINC.QS = CINC.Core.QueryString.parse(window.location.search);
	
    $(document).ready(function () {
        $('body').on('click', '.js-endbecomeuser', CINC.Core.EndBecomeUser);
        $('body').on('click', '.js-corefact-button', CINC.Core.GoToCorefact);
    });

}());

$.fn.serializeObject = function (includeEmpty) {
    includeEmpty = (typeof includeEmpty !== 'undefined' ? includeEmpty : false);
    var o = {};
    var a = this.serializeArray();
    var val;
    $.each(a, function () {
        if (o[this.name]) {
            if (this.name !== 'beds' && this.name !== 'maxprice' && this.name !== 'minprice' && this.name !== 'baths') {
                o[this.name] += ',' + (this.value || '');
            }
        } else if (this.value !== '') {
            o[this.name] = this.value || '';
        } else if (includeEmpty) {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

$.fn.serializeCompositionObject = function () {
    var o = {};
    this.find(':input:disabled').removeAttr('disabled'); // preserve disabled inputs to preserve the integrity of the form model
    var a = this.serializeArray();
    var arrayNotation = /^([^\[\]]+)\[(\d+)\]$/;

    $.each(a, function () {
        var name = this.name;
        var value = this.value;

        // let's also allow for "dot notation" in the input names
        var props = name.split('.');
        var position = o;
        while (props.length) {
            var key = props.shift();
            var matches = arrayNotation.exec(key);   // is it an array property
            if (matches) {
                var p = matches[1];
                var n = matches[2];

                if (!position[p]) {
                    position[p] = [];
                }

                if (!position[p][n]) {
                     position[p][n] = {};
                }

                position = position[p][n];
            } else {
                if (!props.length) {
                    if (!position[key]) {
                         position[key] = value || '';
                    } else if (position[key]) {

                        if (!position[key].push) {
                             position[key] = [position[key]];
                        }

                        position[key].push(value || '');
                    }
                } else {

                    if (!position[key]) {
                        position[key] = {};
                    }
                    position = position[key];
                }
            }
        }
    });

    return o;
};

$.fn.scrollTo = function (target, options, callback) {
    if (typeof options == 'function' && arguments.length == 2) { callback = options; options = target; }
    var settings = $.extend({
        scrollTarget: target,
        offsetTop: 50,
        duration: 500,
        easing: 'swing'
    }, options);
    return this.each(function () {
        var scrollPane = $(this);
        var scrollTarget = (typeof settings.scrollTarget == "number") ? settings.scrollTarget : $(settings.scrollTarget);
        var scrollY = (typeof scrollTarget == "number") ? scrollTarget : scrollTarget.offset().top + scrollPane.scrollTop() - parseInt(settings.offsetTop);
        scrollPane.animate({ scrollTop: scrollY }, parseInt(settings.duration), settings.easing, function () {
            if (typeof callback == 'function') { callback.call(this); }
        });
    });
};

$.fn.copyNamedInputsTo = function(other) {
    return this.each(function() {
        $(':input[name]', this)
            .each(function() {
                $('[name=' + $(this).attr('name') + ']', other).val($(this).val());
            });
    });
};

$.fn.reverse = [].reverse;

CINC.GetSubdomain = function () {
    var subdomain = window.location.href.split("/")[2].split(".")[0];
    return subdomain === 'www' ? '' : subdomain;
};

CINC.GetAgentSubdomain = function () {
    if (CINC.User
        && CINC.User.securityLevel.between(10, 15)
        && (
            CINC.User.subdomain
            || CINC.User.firstName
        )
    ) {
        return CINC.User.subdomain
            ? CINC.User.subdomain
            : CINC.User.firstName;
    }
    return false;
}

CINC.GetFunctionFromString = function (value) {
    if (value) {
        var split = value.split('.'),
            splitLength = split.length,
            i = 1,
            exists = true,
            obj = window[split[0]];
        while (i < splitLength && exists) {
            if (obj) {
                obj = obj[split[i]];
                i += 1;
            }
            else
                exists = false;
        }
        if (exists && typeof obj === 'function')
            return obj;
        else
            console.log('CINC.Core.getFunction - could not derive function from "{0}".'.format(value));
    }
    return false;
};

CINC.IsNullOrEmpty = function (value) {
    return (value === undefined || value === null || value.trim() === "");
};

CINC.keysToLowerCase = function(obj) {
    if (!typeof (obj) === "object" || typeof (obj) === "string" || typeof (obj) === "number" || typeof (obj) === "boolean") {
        return obj;
    }
    var keys = Object.keys(obj);
    var n = keys.length;
    var lowKey;
    while (n--) {
        var key = keys[n];
        if (key === (lowKey = key.toLowerCase()))
            continue;
        obj[lowKey] = CINC.keysToLowerCase(obj[key]);
        delete obj[key];
    }
    return (obj);
};

CINC.ImageUrlFromFilename = function (imageDirectory, filename) {
    return CINC.BaseImageUrlTemplate.format(imageDirectory, filename);
};

CINC.currentTimezone = function() {
    return moment().format('ZZ').replace(/[0]/g,'');
}

//Authorization popup window code
CINC.OAuthPopup = function (options) {
    options.windowName = options.windowName || 'ConnectWithOAuth'; // should not include space for IE
    options.windowOptions = options.windowOptions || 'location=0,status=0,width=800,height=400';
    options.callback = options.callback || function () { window.location.reload(); };
    var self = this;
    self._oauthWindow = window.open(options.path, options.windowName, options.windowOptions);
    self._oauthInterval = window.setInterval(function () {
        if (self._oauthWindow.closed) {
            window.clearInterval(self._oauthInterval);
            options.callback();
        }
    }, 1000);
};

CINC.IsNonDismissableModal = false;
