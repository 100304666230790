CINC = (typeof CINC === 'undefined' ? {} : CINC);
CINC.SellerSuite = {
    el: {},
    g: {}
};

(function (CINC) {

    window.mapCanvas = $('#map-canvas');
    window.mapInput = $('#pac-input');
    var standAloneAutoComplete = (document.getElementById('standAloneAutoComplete'));

    var showHeader = (document.getElementById('showHeader'));
    if (showHeader != null) {
        showHeader = showHeader.innerText;
    }
    var themeColorRGB = (document.getElementById('themeColorRGB'));
    if (themeColorRGB != null) {
        themeColorRGB = themeColorRGB.innerText;
    }
    var formValidationCheck = (document.getElementById('formValidationCheck'));
    if (formValidationCheck != null) {
        formValidationCheck = formValidationCheck.innerText == 'true';
    }

    //set up stand alone autocomplete input
    if (standAloneAutoComplete) {
        var street = $('#DataSource_Street');
        var city = $('#DataSource_City');
        var state = $('#DataSource_State');
        var zip = $('#DataSource_Zip');
        var searchProplat = $('#DataSource_SearchPropertyLatitude');
        var searchProplong = $('#DataSource_SearchPropertyLongitude');
    };

    var ShadeRGBColor = function(color, percent) {
        var f = color.split(","), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent, R = parseInt(f[0].slice(4)), G = parseInt(f[1]), B = parseInt(f[2]);
        return "rgb(" + (Math.round((t - R) * p) + R) + "," + (Math.round((t - G) * p) + G) + "," + (Math.round((t - B) * p) + B) + ")";
    };

    var FinishMapInit = function () {
        if (standAloneAutoComplete) {
            initAutoComplete($('#autocomplete'));
        };

        //stand-alone autocomplete for Index page
        function initAutoComplete(autoCompleteElement) {
            (function pacSelectFirst(input) {
                // store the original event binding function
                var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;
                function addEventListenerWrapper(type, listener) {
                    // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
                    // and then trigger the original listener.

                    if (type == "keydown") {
                        var orig_listener = listener;
                        listener = function (event) {
                            var suggestion_selected = $(".pac-item-selected").length > 0;
                            if (event.which == 13 && !suggestion_selected) {
                                var simulated_downarrow = $.Event("keydown", { keyCode: 40, which: 40 })
                                orig_listener.apply(input, [simulated_downarrow]);
                            }

                            orig_listener.apply(input, [event]);
                        };
                    }

                    // add the modified listener
                    _addEventListener.apply(input, [type, listener]);
                }

                if (input.addEventListener)
                    input.addEventListener = addEventListenerWrapper;
                else if (input.attachEvent)
                    input.attachEvent = addEventListenerWrapper;

            })(autoCompleteElement[0]);

            var autocomplete = new google.maps.places.Autocomplete((autoCompleteElement[0]));
            autocomplete.setFields(['formatted_address','geometry']);

            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();

                var fullAddress = autoCompleteElement.val();
                autoCompleteElement.attr('value', fullAddress);

                //suprisingly - this is easier than trying to parse the place.address_components
                if (place.formatted_address) {
                    var addressSplit = place.formatted_address.split(", ");
                    var fullAddressSplit = (fullAddress ? fullAddress.split(', ') : []);
                    if (street) {
                        street.attr('value', (addressSplit[0] || ''));
                        //if place doesn't include street number, try to find street number on user input text and use that
                        if (!(/\d/.test(street.attr('value'))) && fullAddressSplit && fullAddressSplit.length > 0 && /\d/.test(fullAddressSplit[0])) {
                            street.attr('value', fullAddressSplit[0]);
                        }
                    }
                    if (city)
                        city.attr('value', (addressSplit[1] || ''));
                    if (state) {
                        state.attr('value', (addressSplit[2].split(" ")[0] || ''));
                    }
                    if (zip) {
                        var zipInfo = (addressSplit[2].split(" ")[1] || '');
                        if (!zipInfo.match(/^[0-9]*$/))
                        {
                            // canada
                            zipInfo = addressSplit[2].substring(addressSplit[2].indexOf(" ") + 1, addressSplit[2].length);
                        }
                        zip.attr('value', zipInfo);
                    }
                    if (searchProplat) {
                        lat = place.geometry.location.lat();
                        searchProplat.attr('value', lat);
                    }
                    if (searchProplong) {
                        long = place.geometry.location.lng();
                        searchProplong.attr('value', long);
                    }
                }
            });
        }   
    };

    var validateForm = function () {
        var valid = true;
        for (var i = 0; i < $('select').length; i++) {
            var selectId = '#' + $('select')[i].id;

            if ($(selectId).val() === "PleaseSelect") {
                valid = false;
                $(selectId).siblings('.help-inline').css('color', 'red');
                $(selectId).siblings('.help-inline').show();
            }
            else {
                $(selectId).siblings('.help-inline').hide();
            }
        }

        for (var i = 0; i < $('input[type="text"]').length; i++) {
            var self = $('#' + $('input[type="text"]')[i].id);
            if (self.hasClass('js-number')) {
                if ((self.val() && self.val().match(/\d/g) ? self.val().match(/\d/g).length > 0 : false)) {
                    self.siblings('.help-inline').hide();
                } else {
                    valid = false;
                    self.siblings('.help-inline').css('color', 'red');
                    self.siblings('.help-inline').show();
                }
            } else {
                if (self.val() || !self.hasClass('js-validate')) {
                    self.siblings('.help-inline').hide();
                } else {
                    valid = false;
                    self.siblings('.help-inline').css('color', 'red');
                    self.siblings('.help-inline').show();
                }
            }
        }

        return valid;
    };

    //onload handle header / footer
    //setup form validation check
    $(document).ready(function () {
        var btnInitialColor = $('#btnNext').css("background-color");
        $('.js-header').css('display', 'none');

        if (showHeader === 'True' || showHeader === null) {
            $('.js-top-menu').css('display', 'block');
            $('.js-top-menu span.green').removeClass('green');
            $('.js-header').css('display', 'block');
            $('.js-header span.green').removeClass('green');
            $('#nav a').css('color', themeColorRGB);
            $('.site-logo').css('color', themeColorRGB);
        }
        else {
            $('.js-top-menu').css('display', 'none');
            $('.js-header').css('display', 'none');
            $('#header').css('display', 'none');
            $('#footer').css('display', 'none');
        }
        FinishMapInit();
        if (formValidationCheck) {
            $('input[type="text"]').change(function (evt) {
                evt.preventDefault();
                var self = $('#' + this.id);
                if (self.val()) {
                    self.siblings('.help-inline').hide();
                } else {
                    self.siblings('.help-inline').css('color', 'red');
                    self.siblings('.help-inline').show();
                }
            });

            function resetButtonColor() {
                if (btnInitialColor === undefined)
                    return;

                $('#btnNext').css("background-color", btnInitialColor);
            }

            function shadeButtonColor(isSubmit) {
                if (btnInitialColor === undefined)
                    return;

                $('#btnNext').css("background-color", ShadeRGBColor(btnInitialColor, isSubmit ? 0.2 : 0.1));
            }

            $('form').submit(function (evt) {
                $('#btnNext').attr("disabled", true);
                var searchTermsAgree = $('form').find('.js-searchtermsagree-keep-in-touch');
                if (searchTermsAgree.length === 1 && !searchTermsAgree.prop('checked')) {
                    Notification.Tooltip.Show("Please accept the terms and conditions", $('form').find('.js-searchtermsagree-keep-in-touch'));
                    evt.preventDefault();
                    resetButtonColor();
                    $('#btnNext').removeAttr('disabled');
                    return;
                }

                var formValid = validateForm();

                if (!$('form').valid() || !formValid) {
                    evt.preventDefault();
                    resetButtonColor();
                    $('#btnNext').removeAttr('disabled');
                    return false;
                } else if ($('.js-seller-suite-registration').length) {
                    // There is no great to way to determine if the registration is successful
                    // the form is not being handled by js ajax calls
                    // however if the form is valid (passes the first if condition - phone, email, or name) and the baseline reg. reqs between client and server stay the same (phone, email, and name)
                    // we can infer a succesful registration
                    if (typeof ga !== 'undefined' && ga !== null) {
                    }

                    if (typeof mixpanel !== 'undefined') {
                        mixpanel.track('User Registration Successful');
                    }
                }

                if (street !== undefined && street.val().length === 0) {
                    var firstResult = $(".pac-container .pac-item:first").text();
                    var geocoder = new google.maps.Geocoder();
                    evt.preventDefault();
                    resetButtonColor();
                    geocoder.geocode({ 'address': firstResult }, function (results, status) {
                        if (status == google.maps.GeocoderStatus.OK && results.length > 0) {
                            var result = results[0];
                            if (result.formatted_address) {

                                if ($('#autocomplete')) {
                                    $('#autocomplete').val(result.formatted_address);
                                }

                                var addressSplit = result.formatted_address.split(", ");
                                if (street)
                                    street.attr('value', (addressSplit[0] || ''));
                                if (city)
                                    city.attr('value', (addressSplit[1] || ''));
                                if (state) {
                                    state.attr('value', (addressSplit[2].split(" ")[0] || ''));
                                }
                                if (zip) {
                                    zip.attr('value', (addressSplit[2].split(" ")[1] || ''));
                                }

                                $('#btnNext').click();
                            }
                        }
                    });
                    $('#btnNext').removeAttr('disabled');
                    return;
                }

                var btnNext = $('#btnNext');
                var height = btnNext.height();
                var width = btnNext.width();
                document.querySelector('#btnNext').innerText = "";
                bodymovin.loadAnimation({
                    container: document.getElementById('btnNext'), // Required
                    animationData: loadingSpinner, // Required
                    renderer: 'svg', // Required
                    loop: true, // Optional
                    autoplay: true, // Optional
                });
                btnNext.height(height).width(width);
                $('#btnNext').off('mouseenter mouseleave');
            });

            $('#btnNext').click(function () {
                shadeButtonColor(true);
            });

            $('#btnNext').hover(function() {
                shadeButtonColor();
            }, function () {
                resetButtonColor();
            });
        };
    });
}(CINC));