(function(Modals, ga) {
    Modals.LeadLegalAgreementModal = function () { return new Modals.CincModal('#lead-legal-agreement-modal'); };

    if (typeof CINC !== 'undefined' && CINC.User && CINC.User !== null && CINC.User.AgreedToTermsOfUseAndPrivacyPolicy !== null) {
        var leadLegalModalExists = document.getElementById('lead-legal-agreement-modal');
        if (typeof (leadLegalModalExists) !== 'undefined' && leadLegalModalExists !== null) {
            Modals.LeadLegalAgreementModal().Open();
        }
    }

    $(document).ready(function () {
        $('#lead-legal-agreement-modal-btn').on('click', function (e) {
            e.preventDefault();
            var legalSignIn = CINC.Api.LegalAgreement('agreed');
            legalSignIn.post()
            .success(function (result) {
                    if (result.isSuccess) {
                        Notification.Banner.Success('Thank you for your agreement. Please enjoy viewing the site.');
                        Modals.LeadLegalAgreementModal().Close();
                    } else {
                        Notification.Banner.Error('An unforeseen error has occurred. Please reload the page and try again.');
                    }
                })
                .fail(function (f) {
                    Notification.Banner.Error('Error: unable to save');
                });
        });
    });
})(Modals || {}, typeof ga === 'undefined' ? function () { } : ga);
