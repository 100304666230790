if (CINC.Core.CookiesVanilla.Get('emergency_banner') === 'closed') {
    RemoveEmergencyBanner();
}
var closeEmergencyButton = document.getElementById("close-emergency-notification");
if (closeEmergencyButton) {
    closeEmergencyButton.addEventListener("click", StoreClosedEmergencyBanner);
}

function StoreClosedEmergencyBanner() {
    // store Emergency closure to expire in 7 days
    CINC.Core.CookiesVanilla.Set('emergency_banner', 'closed', 7);
    RemoveEmergencyBanner();
}
function RemoveEmergencyBanner() {
    var emergencyBanner = document.getElementById("emergency-banner");
    if (emergencyBanner) {
        emergencyBanner.parentNode.removeChild(emergencyBanner);
    }
}