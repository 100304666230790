CINC = (typeof CINC === 'undefined' ? {} : CINC);

CINC.Registration = (function (ga, gtag_report_conversion) {
    var finishRegistration = function (shouldReload) {
        shouldReload = shouldReload !== false;

        CINC.Core.WriteConversionScripts();
        if (typeof gtag_report_conversion === "function") {
            if (shouldReload) {
                gtag_report_conversion(window.location.href);
            } else {
                gtag_report_conversion();
            }

            if (typeof ga !== 'undefined') {
            }

            if (typeof mixpanel !== 'undefined') {
                mixpanel.track('Gtag conversion reported');
            }
        }
        var firstname = "";
        if (CINC.User && CINC.User.firstName) {
            firstname = CINC.User.firstName;
        }
        SetLoadAction("NotificationBanner", {
            title: "Registration Successful",
            message: firstname === "" ? "You're all ready to go!" : "You're all ready to go, " + firstname + "!",
            type: "Success"
        });

       if(shouldReload) {
            setTimeout(function () { window.location.reload(); }, 500);
        }
    };

    var isDismissiblePage = function () {
        var restrictedSegments = ["homes"];
        var pathSegment = window.location.pathname.toLowerCase().split('/')[1];

        if ($.inArray(pathSegment, restrictedSegments) > -1) {
            return false;
        }
        return true;
    };

    return {
        elements: {
            SignUpForm: $('.js-signupform-form'),
            SignUpHomeValForm: $('.js-signupform-home-val-form'),
            QuestionHomeValForm: $('.js-question-home-val-form'),
            PhoneForm: $('.js-signupform-required-phone')
        },
        callbacks: {
            registrationSuccess: function (registrationResult) {

                if (typeof mixpanel !== 'undefined') {
                    mixpanel.track('User Registration Successful');
                }

                if (registrationResult.userInfo) {
                    CINC.User = registrationResult.userInfo;
                    IsLoggedIn = true;
                }
                if (document.getElementById('conversion-tracking-scripts') === null) {
                    $('body').append('<div id="conversion-tracking-scripts">' + CINC.Site.ConversionTrackingScripts + '</div>');
                }

                CINC.Core.CookiesVanilla.Set('registration-state', 'registered', 5);

                if (CINC.Site.IsBrandedSite || (CINC.ControllerName === 'Home' && CINC.ActionName === 'App') || (CINC.Core.CookiesVanilla.Get('fbprompt') === '1' || (CINC.QS && CINC.QS['fbprompt'] === '1'))) {
                    finishRegistration();
                }
                else {
                    PromptForSignup('', CINC.CincProduct === "Houses", '2');
                }
            },
            registrationError: function (registrationResult, modalToClose) {
                CINC.Core.CookiesVanilla.Set('registration-state', 'registration-error', 5);
                modalToClose.Close();
                Notification.Banner.Error(registrationResult.description, "Registration Problem");
                PromptForSignup(registrationResult.description, isDismissiblePage());
            },
            registrationApiFailure: function (error, modalToClose) {
                CINC.Core.CookiesVanilla.Set('registration-state', 'registration-error', 5);
                modalToClose.Close();
                Notification.Banner.Error(error.responseText, "Registration Error");
                PromptForSignup("Registration Failed", isDismissiblePage());
            }
        },
        closeSignInModals: function (response) {
            Modals.SignInModal().CloseWithResponse(response);
        },
        finishRegistration: finishRegistration,
        isValid: {
            phoneInputValue: function (phoneInputElement, phoneInputValue) {
                if (phoneInputElement.length !== 1) {
                    return true;    // no phone element, so nothing to validate - technically it is true
                }

                var trimmedPhone = $.trim(phoneInputValue || '');
                var phone = trimmedPhone.replace(/[^0-9]/g, '');
                if (!phone || phone.length < 10) {
                    Notification.Tooltip.Show("Please enter a valid phone number", $(phoneInputElement));
                    return false;
                }
                return true;
            }
        }
    };
})(typeof ga === 'undefined' ? function () { } : ga, typeof gtag_report_conversion === 'undefined' ? function () { console.log("Emulating gtag_report_conversion function.");} : gtag_report_conversion );