CINC = (typeof CINC === 'undefined' ? {} : CINC);
CINC.Core = (typeof CINC.Core === 'undefined' ? {} : CINC.Core);

var PromptForLogin = CINC.Core.PromptForLogin = function (options, isRetry) {
    options = typeof options === 'string' ? { message: options } : options || {};
    $('.js-loginmessage').html(options.message || '');
    $('.js-signinform').show();
    $('.js-signupform').hide();

    if (options.email || options.password) {
        $('.js-signin-email').val(options.email);
        $('.js-signin-password').val(options.password);

        setTimeout(function () {
            $('.js-signin-email').trigger('focus').focus();
            $('.js-signin-password').trigger('focus').focus();
        }, 100);
    }

    Modals.CloseAll();
    var result = Modals.SignInModal().Open();

    // if this is a retry of a failed login, don't reset the dismissable state of the modal
    if (!isRetry) {
        result.Element.next('.modal-window').toggleClass('non-dismissable', false);
    }

    return result;
};

var ShouldShowPrompt = CINC.Core.ShouldShowPrompt = function (force, incrementViews) {
    force = (typeof force === 'undefined' ? false : force);

    if (CINC.User && CINC.User.securityLevel && CINC.User.securityLevel > 0) {
        return { showPrompt: false, allowDismiss: false };
    }

    if (CINC.QS && CINC.QS['showprompt'] === '1') {
        force = true;
    }

    try {

        if (CINC.Core.CookiesVanilla.Get('fbprompt') === '1' || (CINC.QS && CINC.QS['fbprompt'] === '1')) {
            force = true;
            CINC.Core.CookiesVanilla.Set('fbprompt', '1', 1);

            // remove fbprompt=1 from the querystring so customers don't get any ideas

            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
            if (window.location.search) {
                var newsearch = "?" +
                    $.grep(window.location.search.substring(1).split('&'),
                        function (v) {
                            return v != 'fbprompt=1';
                        }).join('&');
                if (newsearch != '?')
                    newurl += newsearch;
            }

            History.pushState({ path: newurl }, '', newurl);

        }
    } catch (err) {
        console.log(err);
    }

    if (CINC.QS && CINC.QS['mobilehideviews']) {
        var maxViews = parseInt(CINC.QS['mobilehideviews']);
        if (maxViews > 0) {
            CINC.Site.PromptViews = maxViews;
            CINC.Core.CookiesVanilla.Set('maxviews', maxViews, 1);
        }
    } else if (typeof CINC.Core.CookiesVanilla.Get('maxviews') !== 'undefined') {
        var maxViews = parseInt(CINC.Core.CookiesVanilla.Get('maxviews'));
        if (maxViews > 0) {
            CINC.Site.PromptViews = maxViews;
        }
    }


    var controller = (CINC.ControllerName || '').toLowerCase();

    var views = 0;
    if (CINC.Core.CookiesVanilla.Get('promptviews')) {
        views = (parseInt(CINC.Core.CookiesVanilla.Get('promptviews')));
    }

    if (!force) {
        if (CINC.Site.PromptLevel === 0 || controller === 'sellersuite') {
            return { showPrompt: false, allowDismiss: false };
        }
        switch (CINC.Site.PromptPages) {
            case 0:
                if (controller === 'account') {
                    return { showPrompt: false, allowDismiss: false };
                }
                break;
            case 1:
                if (!(controller === 'details' || controller === 'propertydetails')) {
                    return { showPrompt: false, allowDismiss: false };
                }
                break;
            case 2:
                if (!(controller === 'search' || controller === 'castlesearch')) {
                    return { showPrompt: false, allowDismiss: false };
                }
                break;
            case 3:
                if (!(controller === 'details' || controller === 'propertydetails' || controller === 'search' || controller === 'castlesearch')) {
                    return { showPrompt: false, allowDismiss: false };
                }
                break;
        }

        if(incrementViews){
            views = views + 1;
        }
        CINC.Core.CookiesVanilla.Set('promptviews', views, 7);
        if (views <= CINC.Site.PromptViews) {
            return { showPrompt: false, allowDismiss: false };
        }

    }

    var allowDismiss = !(force || CINC.Site.PromptLevel > 1);
    return { showPrompt: true, allowDismiss: allowDismiss };
};

var ShowPrompt = CINC.Core.ShowPrompt = function (force) {
    var shouldShowObj = ShouldShowPrompt(force, true);
    if (shouldShowObj && shouldShowObj.showPrompt) {
        CINC.Core.PromptForSignup('', shouldShowObj.allowDismiss);
    }
};

var PromptForSignup = CINC.Core.PromptForSignup = function (message, allowDismiss, step) {

    allowDismiss = (typeof allowDismiss === 'undefined' ? true : allowDismiss);
    step = (typeof step === 'undefined' ? '1' : String(step));

    if (!allowDismiss) {
        CINC.IsNonDismissableModal = true;
    }

    var result = null;
    if (message === 'schedule') {
        result = Modals.SignUpScheduleModal().Open();
    }
    else {
        result = PromptForLogin(message);
    }

    //CINC.Facebook.SignupFormEvent();

    CINC.Core.WriteConversionScripts();

    $('.js-signup-step').each(function () {
        var $step = $(this);
        $step.toggle($step.attr('data-signup-step') === step);
    });

    $('.js-signupmessage').html(message || 'Join us and see how our powerful notebook features simplify your home search process, saving you time and hassle!');
    $('.js-signupform').show();
    $('.js-signinform').hide();

    var socialButtons = $('.js-socialsignin-buttons');
    if (socialButtons.hasClass('hidden')) {
        socialButtons.removeClass('hidden');
        socialButtons.find('iframe[data-src]').each(function () {
            $(this).attr('src', $(this).attr('data-src'));
            $(this).attr('data-src', null);
        });
    }


    $('.js-signup-close').toggle(allowDismiss);
    result.Element.next('.modal-window').toggleClass('non-dismissable', !allowDismiss);

    return result;
};

function PromptForValidCellPhone(options) {
    var result = Modals.LeadValidCellModal().Open();
    return result;
};

var InitAcceptTerms = CINC.Core.InitAcceptTerms = function () {
    if (!CINC.Site.SearchRequiresAccept) {
        return;
    }
    $('.js-acceptterms-accept').on('click', function () {
        CINC.Core.CookiesVanilla.Set('acceptterms', '1');


        $('.js-acceptterms-checkbox').prop('checked', true);
        $('#acceptterms-modal').prop('checked', false);
        if (acceptTermsCallback !== null && typeof acceptTermsCallback === 'function') {
            acceptTermsCallback();
            acceptTermsCallback = null;
        }
    });
    if ((CINC.ControllerName || '').toLowerCase() === 'search' && ((CINC.ActionName || '') !== 'PDQ')) {
        AcceptTerms(false);
    }
};

var AcceptTerms = CINC.Core.AcceptTerms = function (force, callback) {
    if (!force) {
        if (!CINC.Site.SearchRequiresAccept || (CINC.User && CINC.User.SecurityLevel >= 5) || CINC.Core.CookiesVanilla.Get('acceptterms') === 1) {
            if (typeof callback === 'function') {
                callback();
            }
            acceptTermsCallback = null;
            return true;
        }
    }
    $('#acceptterms-modal').prop('checked', true);
    acceptTermsCallback = callback;
    return false;
};

var InitOptIn = CINC.Core.InitOptIn = function () {
    if (!CINC.User || !CINC.User.user_IsImport) {
        return;
    }
    $('.js-importoptin-accept').on('click', function () {
        $('#optinimport-modal').prop('checked', false);
        // ajax request
        var data = {
            MDID: CINC.User.mdid,
            CellPhone: $('.optin-modal').find('input[name="cellphone"]').val(),
        };
        CINC.Api.Account('importoptin')
            .post(data);
    });
};

var OptInImport = CINC.Core.OptInImport = function () {
    if (!CINC.User) {
        return;
    }

    if (CINC.User.user_IsImport && !CINC.User.user_Import_OptedIn && CINC.User.user_UnsubscribeText && !CINC.Core.CookiesVanilla.Get('import_optedin')) {
        $('.optin-modal').find('input[name="cellphone"]').val(CINC.User.cellPhone);
        $('#optinimport-modal').prop('checked', true);
        CINC.Core.CookiesVanilla.Set('import_optedin', '1');
    }
};


//TODO: rewrite 
$(function () {
    $("#modal-1").on("change", function () {
        if ($(this).is(":checked")) {
            $("body").addClass("modal-open");
        } else {
            $("body").removeClass("modal-open");
        }
    });

    $(".modal-window").on("click", function () {
        if ($(this).hasClass('non-dismissable')) return;

        $(".modal-state:checked").prop("checked", false).change();
    });

    $(".modal-inner").on("click", function (e) {
        if ($(e.target).hasClass('toggleSignup') && CINC && CINC.Facebook && CINC.Facebook.SignupFormEvent) {
            CINC.Facebook.SignupFormEvent();
        }

        e.stopPropagation();
    });

});