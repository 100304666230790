if (CINC.Core.CookiesVanilla.Get('cookie_banner') === 'accepted') {
    RemoveCookieBanner();
}
var acceptCookieButton = document.getElementById("accept-cookie-notification");
if (acceptCookieButton) {
    acceptCookieButton.addEventListener("click", StoreAcceptedCookiePopUp);
}

function StoreAcceptedCookiePopUp() {
    // store cookie Set it expire in 90 days
    CINC.Core.CookiesVanilla.Set('cookie_banner', 'accepted', 90);
    RemoveCookieBanner();
}
function RemoveCookieBanner() {
    var cookieBanner = document.getElementById("cookie-banner");
    if (cookieBanner) {
        cookieBanner.parentNode.removeChild(cookieBanner);
    }
}
