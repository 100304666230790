CINC = (typeof CINC === 'undefined' ? {} : CINC);
CINC.Core = (typeof CINC.Core === 'undefined' ? {} : CINC.Core);
$(document).ready(function () {
    // HACK? idkwatevs
     //if we've defined a page load action, invoke it and clear it.
     var loadAction = CINC.Core.CookiesVanilla.Get("CINC-LoadAction");

    if (loadAction) {
         var handler = CINC.Core.LoadActionHandlers[loadAction];
         if (handler) {
             var payload = JSON.parse(CINC.Core.CookiesVanilla.Get("CINC-LoadActionPayload"));
             handler(payload);
         }

         CINC.Core.CookiesVanilla.Remove("CINC-LoadAction");
         CINC.Core.CookiesVanilla.Remove("CINC-LoadActionPayload");
    }


     $('.js-update-cellphone').on('click', function (e) {
        e.preventDefault();
        var cellPhone = $('.js-new-cellphone').val().replace('-', '');
        if (cellPhone.length !== 10 || /[a-zA-Z]/i.test(cellPhone)) {
            Notification.Banner.Error("Phone number must be 10 digits and contain no characters.");
            return;
        }

        var args = {
            CellPhone: cellPhone,
        };
        CINC.Api.Account('update')
            .post(args)
            .success(function (u) {
                Notification.Banner.Success('Your cell phone number has been updated successfully.', 'Updated Cell Phone!');
                Modals.LeadValidCellModal().Close();
            })
            .fail(function (f) {
                Modals.LeadValidCellModal().Close();
            });
    });

    $('.js-cancel-cellphone').on('click', function (e) {
        e.preventDefault();
        Modals.LeadValidCellModal().Close();
    });

    if (CINC.User && (CINC.User.securityLevel === 5) && (CINC.User.validCellPhone === "False") && (CINC.User.count_Login % 3 === 0) && document.cookie.indexOf('PromptForValidCellPhone=true') === -1) {
        document.cookie = "PromptForValidCellPhone=true";
        PromptForValidCellPhone();
    }

    CINC.Core.ShowPrompt(false);
    CINC.Core.InitAcceptTerms();
    CINC.Core.InitOptIn();
});
